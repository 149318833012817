<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-sys__dict">
      <el-form
        :inline="true"
        :model="dataForm"
        @keyup.enter.native="getDataList()"
      >
        <el-form-item>
          <!-- <el-input
            v-model="dataForm.channelName"
            :placeholder="$t('statistics.selectChannel')"
            clearable
          ></el-input> -->
          <el-select
            v-model="dataForm.channelId"
            :placeholder="$t('statistics.selectChannel')"
            label="smsName"
            :clearable="true"
            style="width: 100%"
          >
            <el-option
              v-for="item in channelList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="dataForm.startDate"
            type="date"
            value-format="yyyy-MM-dd"
            :placeholder="$t('statistics.selectStartDate')"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
                  <el-date-picker
                    v-model="dataForm.endDate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    :placeholder="$t('statistics.selectEndDate')"
                  >
                  </el-date-picker>
                </el-form-item>
        <!-- <el-form-item>
          <el-button type="primary" @click="createStatistics()">{{
            $t('query')
          }}</el-button>
        </el-form-item> -->
        <el-form-item>
          <el-button @click="getDataList()">{{ $t('query') }}</el-button>
        </el-form-item>
      </el-form>
      <el-table
        v-loading="dataListLoading"
        :data="dataList"
        border
        @selection-change="dataListSelectionChangeHandle"
        @sort-change="dataListSortChangeHandle"
        style="width: 100%"
      >
        <el-table-column
          prop="statisticsDate"
          :label="$t('statistics.statisticsDate')"
          header-align="center"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="channelName"
          :label="$t('statistics.channelName')"
          header-align="center"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="adPlaceName"
          :label="$t('statistics.adPlaceName')"
          header-align="center"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="clickCount"
          :label="$t('statistics.clickCount')"
          header-align="center"
          align="center"
        ></el-table-column>
        <!-- <el-table-column
          prop="recordStatus_name"
          :label="$t('statistics.recordStatus')"
          header-align="center"
          align="center"
        ></el-table-column> -->
      </el-table>
      <el-pagination
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="limit"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="pageSizeChangeHandle"
        @current-change="pageCurrentChangeHandle"
      >
      </el-pagination>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module';
import { addDynamicRoute } from '@/router';
export default {
  mixins: [mixinViewModule],
  data() {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/web/api/statistics/click/list',
        getDataListIsPage: true,
      },
      dataForm: {
        channelId: '',
        date: '',
      },
      channelList: [],
    };
  },
  created() {
    this.getChannelList();
  },
  methods: {
    // 子级
    childHandle(row) {
      // 路由参数
      const routeParams = {
        routeName: `${this.$route.name}__${row.id}`,
        title: `${this.$route.meta.title} - ${row.dictType}`,
        path: 'sys/dict-data',
        params: {
          dictTypeId: row.id,
        },
      };
      // 动态路由
      addDynamicRoute(routeParams, this.$router);
    },
    resetData() {
      Object.assign(this.$data, this.$options.data());
    },
    getChannelList() {
      this.$http
        .get('/web/api/channel/list')
        .then((res) => {
          console.log(res);
          if (res.data.code === 0) {
            this.channelList = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    createStatistics() {
      console.log(this.dataForm);
      this.$http
        .post('/web/api/statistics/click/settling', this.dataForm)
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              this.resetData();
              this.getDataList();
            },
          });
        })
        .catch(() => {});
    },
  },
};
</script>
